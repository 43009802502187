* {
  font-family: Arial, Helvetica, sans-serif;
}

html,
body {
  margin: 0;
  border: 0;
  padding: 0;
  color: #eddcd2;
  background: url(green-fibers.png) top left repeat, url(green-fibers.png) top left no-repeat ;

  /* background-color: #a5a58d; */
  background-size: auto, cover;
}

main {
  margin: 0 auto;
  /* width: 100%; */
  padding: 20px;
}

main {
  text-align: center;
}

h1 {
  font-size: 3.5em;
}

.container { 
  display:flex;
  align-self: center;
  justify-content: space-evenly;
  /* border: 1px solid purple; */
  align-items: flex-start;
}

#inputBody {
  /* border: 1px dotted red; */
}
#outputBody {
  /* border: 1px dashed green; */
}

#inputBody, #outputBody {
width:40vw;
}

input {
  background-color: #efede7;
  height: 25vh;
  width:100%;
  /* border: 1px dotted pink; */
}

.button-bar{
  display: flex;
  flex-direction: column;
  width: 20vw;
}